import { lazy } from "react";
import IRouteType from "./model";
const Home = lazy(() => import("../pages/Home"));
const DeepSeekAi = lazy(() => import("../pages/DeepSeekAi"));
const Login = lazy(() => import("../pages/login"));
const Layout = lazy(() => import("../layout"));
const Inbox = lazy(() => import("../pages/inbox/index"));
const Outbox = lazy(() => import("../pages/outbox/index"));
const Auditing = lazy(() => import("../pages/Auditing"));
const AddressBook = lazy(() => import("../pages/addressBook/index"));
const Search = lazy(() => import("../pages/search/index"));
const WriteEmail = lazy(() => import("../pages/writeEmail/index"));
const Drafts = lazy(() => import("../pages/drafts/index"));
const PendingShipment = lazy(() => import("../pages/pendingShipment/index"));
const Collect = lazy(() => import("../pages/collect/index"));
const RecycleBin = lazy(() => import("../pages/recycleBin/index"));
const Attachment = lazy(() => import("../pages/attachment/index"));
const UserCenter = lazy(() => import("../pages/userCenter/index"));
const TransactionsMail = lazy(() => import("../pages/transactionsMail/index"));
const NotificationList = lazy(() => import("../pages/notificationList/index"));
const NotFound = lazy(() => import("../pages/NotFound"));
const BannerDetail = lazy(() => import("../pages/bannerDetail/index"));
const route: IRouteType.IRoute[] = [
  {
    path: "/",
    element: Layout,
    children: [
      {
        path: "/",
        element: Home,
      },
      {
        path: "/deepSeekAi",
        element: DeepSeekAi,
      },
      {
        path: "/inbox",
        element: Inbox,
      },
      {
        path: "/outbox",
        element: Outbox,
      },
      {
        path: "/AddressBook",
        element: AddressBook,
      },
      {
        path: "/Search",
        element: Search,
      },
      {
        path: "/WriteEmail",
        element: WriteEmail,
      },
      {
        path: "/Drafts",
        element: Drafts,
      },
      {
        path: "/pendingShipment",
        element: PendingShipment,
      },
      {
        path: "/collect",
        element: Collect,
      },
      {
        path: "/recycleBin",
        element: RecycleBin,
      },
      {
        path: "/attachment",
        element: Attachment,
      },
      {
        path: "/userCenter",
        element: UserCenter,
      },
      {
        path: "/transactionMail",
        element: TransactionsMail,
      },
      {
        path: "/notificationList",
        element: NotificationList,
      },
      {
        path: "/bannerDetail",
        element: BannerDetail,
      },
    ],
  },
  {
    path: "/login",
    element: Login,
  },
  {
    path: "/auditing",
    element: Auditing,
  },
  {
    path: "/*",
    element: NotFound,
  },
];

export default route;
